import React, { useMemo } from 'react'
import { NotFound } from 'components/shared/NotFound'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useRouter } from 'next/router'
import { Header } from 'layouts/Header'
import { DefaultLayout } from 'layouts/DefaultLayout'
import { Footer } from 'layouts/Footer'
import { useTranslation } from 'next-i18next'
import { useEventPlaceQuery } from 'hooks/queries/event'
import PropTypes from 'helpers/proptypes'
import { useSearchQuery } from 'hooks/search/useSearchQuery'
import { EventsSearchSection } from 'components/shared/sections'
import { Heading } from '@vizeat/components/es6/components/Heading'
import { useUserQuery } from 'hooks/queries/user'

const eventPathRegex = /^\/events\/(\d+)/
const userPathRegex = /^\/users\/(\d+)/

export const getStaticProps = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale || 'en')),
    },
  }
}

function formatPlace(data) {
  if (!data.locality || !data.country) return null
  return `${data.locality}, ${data.country}`
}

function NotFoundPageWithEventsCarousel({ eventId, userId }) {
  const { t } = useTranslation()
  const { data: placeQueryFromEvent } = useEventPlaceQuery(eventId, {
    select: formatPlace,
    enabled: !!eventId,
  })

  const { data: placeQueryFromUser } = useUserQuery(userId, undefined, {
    select: formatPlace,
    enabled: !!userId,
  })

  const q = placeQueryFromEvent || placeQueryFromUser
  const searchPayload = useMemo(() => ({ q }), [q])
  const searchOptions = useMemo(() => ({ enabled: !!q }), [q])
  const { selectData } = useSearchQuery(searchPayload, searchOptions)
  const events = selectData(({ events }) => events)

  const title = useMemo(() => {
    if (eventId) return t('Event::Sorry, we could not find the event you are looking for')
    if (userId) return t('Event::Sorry, we could not find the user you are looking for')
    return undefined
  }, [eventId, t, userId])

  return (
    <DefaultLayout title={t('NotFound::Not found')}>
      <Header showSearch />
      <NotFound message={title}>
        {events.size > 0 && (
          <>
            <Heading as='h2' textAlign='center'>
              {t('404::Other experiences near {{locality}}', {
                locality: q,
              })}
            </Heading>
            <EventsSearchSection
              slideWidth='240px'
              cellSpacing={12}
              searchPayload={searchPayload}
              searchOptions={searchOptions}
            />
          </>
        )}
      </NotFound>
      <Footer />
    </DefaultLayout>
  )
}

NotFoundPageWithEventsCarousel.propTypes = {
  eventId: PropTypes.number,
  userId: PropTypes.number,
}

NotFoundPageWithEventsCarousel.defaultProps = {
  eventId: undefined,
  userId: undefined,
}

export default function NotFoundPage() {
  const { asPath } = useRouter()
  const eventId = asPath.match(eventPathRegex)?.[1]
  const userId = asPath.match(userPathRegex)?.[1]

  if (eventId || userId) return <NotFoundPageWithEventsCarousel eventId={Number(eventId)} userId={Number(userId)} />

  return <NotFound />
}
